<template>
  <div>
    <b-container fluid class="content menupage">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <el-tabs
        type="border-card"
        v-model="active_tab"
        @tab-click="changed_tabs"
        :before-leave="check_changed_tabs"
      >
        <el-tab-pane
          v-for="menu in menulist"
          :key="menu.IDn"
          :label="menu.Name1"
          :name="menu.IDn + 'tab'"
          lazy
        >
          <b-card>
            <el-form ref="form">
              <b-row class="mb-0">
                <b-col cols="3">
                  <el-form-item size="mini" :label="$t('Name') + '1'">
                    <el-input
                      placeholder="Name1"
                      v-model="cur_menu.Name1"
                      @change="change_cur_menu()"
                      class="w-50"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col cols="3">
                  <el-form-item size="mini" :label="$t('Name') + '2'">
                    <el-input
                      placeholder="Name2"
                      v-model="cur_menu.Name2"
                      @change="change_cur_menu()"
                      class="w-50"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col cols="3">
                  <el-form-item size="mini" :label="$t('Price')">
                    <el-select
                      size="mini"
                      class="w-50"
                      v-model="cur_menu.PriceGroup"
                      placeholder="Price Group"
                      @change="change_cur_menu()"
                    >
                      <el-option label="A" value="A"></el-option>
                      <el-option label="B" value="B"></el-option>
                      <el-option label="C" value="C"></el-option>
                      <el-option label="D" value="D"></el-option>
                      <el-option label="E" value="E"></el-option>
                      <el-option label="F" value="F"></el-option>
                      <el-option label="G" value="G"></el-option>
                      <el-option label="H" value="H"></el-option>
                      <el-option label="I" value="I"></el-option>
                      <el-option label="J" value="J"></el-option>
                    </el-select>
                  </el-form-item>
                </b-col>
                <b-col cols="3">
                  <el-switch
                    size="mini"
                    v-model="cur_menu.IsOnline"
                    :width="30"
                    :active-value="1"
                    :inactive-value="0"
                    @change="change_online"
                  ></el-switch>
                  <span class="ml-1">{{ $t("Online") }}</span>
                  <span>
                    <el-button
                      v-if="hasLdwUpload && (cur_menu.IsOnline == 1) && !isDraft"
                      class="upload_ldw"
                      @click="upload_ldw"
                      size="mini"
                      type="primary"
                    >{{ $t("Update LDW") }}</el-button>
                  </span>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="3">
                  <el-form-item size="mini" :label="$t('Start Date')">
                    <el-date-picker
                      v-model="cur_menu.StartDate"
                      @change="change_cur_menu()"
                      type="date"
                      size="mini"
                      class="widthdate"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      placement="bottom-start"
                      placeholder="Pick a day">
                    </el-date-picker>
                  </el-form-item>
                </b-col>
                <b-col cols="3">
                  <el-form-item size="small" :label="$t('End Date')">
                    <el-date-picker
                      v-model="cur_menu.EndDate"
                      @change="change_cur_menu()"
                      type="date"
                      size="mini"
                      class="widthdate"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      placement="bottom-start"
                      placeholder="Pick a day">
                    </el-date-picker>
                  </el-form-item>
                </b-col>
                <b-col cols="6">
                  <el-checkbox-group
                    v-model="cur_menu.LocationID"
                    @change="changed_store()"
                  >
                    <el-checkbox
                      v-for="store in storelist"
                      :key="store.LocationID"
                      :label="store.LocationID"
                      >{{ store.LocationName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="6" class="mb-1 text-right">
                  <el-button v-if="isDraft" type="default" size="mini" @click="cancel_change()">{{ $t('Cancel Change') }}</el-button>
                  <el-button v-if="isDraft" type="primary" size="mini" @click="save_change()">{{ $t('Save Change') }}</el-button>
                </b-col>
                <b-col cols="6">
                  <xlsx-workbook @change="function(sheet){change_for_menu(sheet, menu.IDn);}" @created="created_for_menu">
                    <xlsx-sheet
                      :collection="sheet.data"
                      v-for="sheet in export_sheets_for_menu"
                      :key="sheet.name"
                      :sheet-name="sheet.name"
                    />
                    <xlsx-download :filename="export_filename" disable-wrapper-click>
                      <template #default="{ download }">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="export_menu(download)"
                        >
                          {{ $t("Export") }}
                        </el-button>
                      </template>
                    </xlsx-download>
                  </xlsx-workbook>
                </b-col>
              </b-row>
              <hr class="page_hr" />
              <b-row class="mt-2">
                <div>
                  <label>{{ $t('Display Name') }} :</label>
                  <el-radio-group class="ml-1 mt-2" v-model="showLanguage">
                    <el-radio class="ml-2" label="1">{{ $t('Name') }}1</el-radio>
                    <el-radio class="ml-2" label="2">{{ $t('Name') }}2</el-radio>
                  </el-radio-group>
                  <span class="ml-6">
                    <el-switch
                      v-model="addOtherCategory"
                      :active-value="1"
                      :inactive-value="0"
                      @change="add_other_category"
                    ></el-switch>
                    <span class="ml-2 mr-4">{{
                      $t("Add Product From Other Category")
                    }}</span>
                    <el-switch
                      v-model="changeBtnColor"
                      :active-value="1"
                      :inactive-value="0"
                    ></el-switch>
                    <span v-if="!changeBtnColor" class="ml-2 mr-4">{{
                      $t("Button Color")
                    }}</span>
                    <span v-else class="ml-2">
                      <el-color-picker :predefine="predefineColors" v-model="btnToColor" size="mini" class="menu-button-color"></el-color-picker>
                      Select Color
                      <b-button
                        variant="success"
                        size="sm"
                        @click="set_all_botton_color($event, 'all')"
                      >
                        Set All
                      </b-button>
                    </span>
                  </span>
                </div>
              </b-row>
              <b-row class="mt-2">
                <div class="left_menu_font" :style="panel_left">
                  <div class="border border-dark pl-1">
                    {{ $t("Department") }}
                    <div v-if="!addOtherCategory" style="position: absolute;top:1px;right:0px;">
                      <!-- <i @click="remove_all('department')" class="el-icon-caret-left border border-success mr-1" style="font-size: 1rem; color: #2dce89"></i> -->
                      <i @click="add_all('department')" class="el-icon-d-arrow-right border border-success mr-3" style="font-size: 1rem; color: #2dce89"></i>
                    </div>
                  </div>
                  <div :class="panel_small?'department_div2':'department_div'">
                    <div v-for="prod in departmentlist" :key="prod.IDn" class="border border-secondary" :class="{ 'border-info': cur_DepartmentIDn==prod.IDn}">
                      <div class="pl-1" style="position: relative;">
                        <div @click="select_product(prod.IDn, 'department')" v-if="showLanguage=='1'" :class="panel_small?'left_panel_sm_font':''" class="leftmenuitem">{{ prod.Name1 }}</div>
                        <div @click="select_product(prod.IDn, 'department')" v-else :class="panel_small?'left_panel_sm_font':''" class="leftmenuitem">{{ prod.Name2 }}</div>
                        <div v-if="prod.show && !addOtherCategory" @click="add_product(prod.IDn, 'department')" class="el-icon-caret-right border border-success m-1" style="position: absolute;top:1px;right:0px; color: #2dce89"></div>
                      </div>
                    </div>
                  </div>
                  <div class="border border-dark pl-1">
                    {{ $t("Category") }}
                    <div v-if="!addOtherCategory" style="position: absolute;top:1px;right:0px;">
                      <!-- <i @click="remove_all('category')" class="el-icon-caret-left border border-success mr-1" style="font-size: 1rem; color: #2dce89"></i> -->
                      <i @click="add_all('category')" class="el-icon-d-arrow-right border border-success mr-3" style="font-size: 1rem; color: #2dce89"></i>
                    </div>
                  </div>
                  <div :class="panel_small?'category_div2':'category_div'">
                    <div v-for="prod in categorylist" :key="prod.IDn" class="border border-secondary" :class="{ 'border-info': cur_CategoryIDn==prod.IDn}">
                      <div class="d-flex pl-1">
                        <div @click="select_product(prod.IDn, 'category')" v-if="showLanguage=='1'" :class="panel_small?'left_panel_sm_font':''" class="leftmenuitem">{{ prod.Name1 }}</div>
                        <div @click="select_product(prod.IDn, 'category')" v-else :class="panel_small?'left_panel_sm_font':''" class="leftmenuitem">{{ prod.Name2 }}</div>
                        <div v-if="prod.show && !addOtherCategory" @click="add_product(prod.IDn, 'category')" class="el-icon-caret-right ml-auto border border-success m-1" style="color: #2dce89"></div>
                      </div>
                    </div>
                  </div>
                  <div class="border border-dark pl-1">
                    {{ $t("Product") }}
                    <div v-if="!addOtherCategory" style="position: absolute;top:1px;right:0px;">
                      <i @click="remove_all('product')" class="el-icon-d-arrow-left border border-success mr-1" style="font-size: 1rem; color: #2dce89"></i>
                      <i @click="add_all('product')" class="el-icon-d-arrow-right border border-success mr-1" style="font-size: 1rem; color: #2dce89"></i>
                    </div>
                  </div>
                  <div :class="panel_small?'product_div2':'product_div'">
                    <div v-for="prod in productlist" :key="prod.IDn" class="border border-secondary" :class="{ 'border-info': cur_ProductIDn==prod.IDn}">
                      <div v-if="prod.Status !== 'Deactive'" class="d-flex pl-1">
                        <div @click="select_product(prod.IDn, 'product')" v-if="showLanguage=='1'" :class="panel_small?'left_panel_sm_font':''" class="leftmenuitem">{{ prod.Name1 }}</div>
                        <div @click="select_product(prod.IDn, 'product')" v-else :class="panel_small?'left_panel_sm_font':''" class="leftmenuitem">{{ prod.Name2 }}</div>
                        <div v-if="prod.show" @click="add_product(prod.IDn, 'product')" class="el-icon-caret-right ml-auto border border-success mt-1 mr-1" style="color: #2dce89"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-0 ml-2">
                  <div :class="panel_small?'pad_panel2':'pad_panel'" v-on:drop="drop($event)" v-on:dragover="allowDrop($event)">
                    <div
                      id="department_panel_id"
                      class="panel_list"
                      :style="department_list_size"
                    >
                      <MenuBtn
                        v-for="prod in curmenu_departmentlist"
                        :key="prod.idx"
                        draggable="true"
                        :showLang="showLanguage"
                        :Name2="prod.Name2"
                        :Name1="prod.Name1"
                        :BtnColor="prod.BtnColor"
                        :mywidth="menuDeptBtnWidth"
                        :myheight="menuDeptBtnHeight"
                        :fontSize="panel_small?(cur_menu.DepartmentFontSize/2):cur_menu.DepartmentFontSize"
                        :fontWeight="cur_menu.DepartmentFontWeight"
                        :fontColor="cur_menu.DepartmentFontColor"
                        @dragstart="drag($event, prod, 'department')"
                        @removeButton="remove_cur_department($event, prod)"
                        @click.native="edit_department($event, prod)"
                      >
                      </MenuBtn>
                    </div>
                    <div
                      class="hr-line"
                      id="department-hr"
                      draggable="true"
                      @dragstart="drag($event, null, 'dept_cate')"
                      >
                    </div>
                    <div
                      id="category_panel_id"
                      class="panel_list"
                      :style="category_list_size"
                    >
                      <MenuBtn
                        v-for="prod in curmenu_categorylist"
                        :key="prod.idx"
                        draggable="true"
                        :showLang="showLanguage"
                        :Name2="prod.Name2"
                        :Name1="prod.Name1"
                        :BtnColor="prod.BtnColor"
                        :mywidth="menuCateBtnWidth"
                        :myheight="menuCateBtnHeight"
                        :fontSize="panel_small?(cur_menu.CategoryFontSize/2):cur_menu.CategoryFontSize"
                        :fontWeight="cur_menu.CategoryFontWeight"
                        :fontColor="cur_menu.CategoryFontColor"
                        @dragstart="drag($event, prod, 'category')"
                        @removeButton="remove_cur_category($event, prod)"
                        @click.native="edit_category($event, prod)"
                      >
                      </MenuBtn>
                    </div>
                    <div
                      class="hr-line"
                      id="category-hr"
                      draggable="true"
                      @dragstart="drag($event, null, 'cate_prod')"
                      >
                    </div>
                    <div
                      id="product_panel_id"
                      class="panel_list"
                      :style="product_list_size"
                      >
                      <MenuBtn
                        v-for="prod in curmenu_productlist"
                        :key="prod.idx"
                        draggable="true"
                        :showLang="showLanguage"
                        :Name2="prod.Name2"
                        :Name1="prod.Name1"
                        :BtnColor="prod.BtnColor"
                        :mywidth="menuProdBtnWidth"
                        :myheight="menuProdBtnHeight"
                        :fontSize="panel_small?(cur_menu.ProductFontSize/2):cur_menu.ProductFontSize"
                        :fontWeight="cur_menu.ProductFontWeight"
                        :fontColor="cur_menu.ProductFontColor"
                        @dragstart="drag($event, prod, 'product')"
                        @removeButton="remove_cur_product($event, prod)"
                        @click.native="edit_product($event, prod)"
                      >
                      </MenuBtn>
                    </div>
                  </div>
                  <div class="pt-3">
                    <b-row>
                      <b-col> {{ $t("The sketch map is different from the actual display") }} </b-col>
                    </b-row>
                    <b-row>
                      <b-col> </b-col>
                      <b-col>
                        {{ $t("Department") }}
                      </b-col>
                      <b-col>
                        {{ $t("Category") }}
                      </b-col>
                      <b-col>
                        {{ $t("Product") }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right">
                        {{ $t("Rows") }}
                      </b-col>
                      <b-col>
                        <el-input-number v-model="cur_menu.DepartmentRow" @change="change_cur_menu()" size="mini" :min="1" :max="9"></el-input-number>
                      </b-col>
                      <b-col>
                        <el-input-number v-model="cur_menu.CategoryRow" @change="change_cur_menu()" size="mini" :min="1" :max="9"></el-input-number>
                      </b-col>
                      <b-col>
                        <el-input-number v-model="cur_menu.ProductRow" @change="change_cur_menu()" size="mini" :min="1" :max="9"></el-input-number>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right">
                        {{ $t("Cols") }}
                      </b-col>
                      <b-col>
                        <el-input-number v-model="cur_menu.DepartmentColumn" @change="change_cur_menu()" size="mini" :min="1" :max="9"></el-input-number>
                      </b-col>
                      <b-col>
                        <el-input-number v-model="cur_menu.CategoryColumn" @change="change_cur_menu()" size="mini" :min="1" :max="9"></el-input-number>
                      </b-col>
                      <b-col>
                        <el-input-number v-model="cur_menu.ProductColumn" @change="change_cur_menu()" size="mini" :min="1" :max="9"></el-input-number>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right">
                        Font Size
                      </b-col>
                      <b-col>
                        <el-select v-model="cur_menu.DepartmentFontSize" @change="change_cur_menu()" size="mini">
                          <el-option v-for="item in fontSizeList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                      </b-col>
                      <b-col>
                        <el-select v-model="cur_menu.CategoryFontSize" @change="change_cur_menu()" size="mini">>
                          <el-option v-for="item in fontSizeList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                      </b-col>
                      <b-col>
                        <el-select v-model="cur_menu.ProductFontSize" @change="change_cur_menu()" size="mini">>
                          <el-option v-for="item in fontSizeList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right">
                        Font Weight
                      </b-col>
                      <b-col>
                        <el-select v-model="cur_menu.DepartmentFontWeight" @change="change_cur_menu()" size="mini">>
                          <el-option v-for="item in fontWeightList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                      </b-col>
                      <b-col>
                        <el-select v-model="cur_menu.CategoryFontWeight" @change="change_cur_menu()" size="mini">>
                          <el-option v-for="item in fontWeightList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                      </b-col>
                      <b-col>
                        <el-select v-model="cur_menu.ProductFontWeight" @change="change_cur_menu()" size="mini">>
                          <el-option v-for="item in fontWeightList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                        </el-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right">
                        Font Color
                      </b-col>
                      <b-col>
                        <el-color-picker :predefine="predefineColors" v-model="cur_menu.DepartmentFontColor" size="small" @change="change_cur_menu()"></el-color-picker>
                      </b-col>
                      <b-col>
                        <el-color-picker :predefine="predefineColors" v-model="cur_menu.CategoryFontColor" size="small" @change="change_cur_menu()"></el-color-picker>
                      </b-col>
                      <b-col>
                        <el-color-picker :predefine="predefineColors" v-model="cur_menu.ProductFontColor" size="small" @change="change_cur_menu()"></el-color-picker>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right">
                        Button Color
                      </b-col>
                      <b-col>
                        <el-color-picker :predefine="predefineColors" v-model="btnDToColor" size="small" @change="set_all_botton_color($event, 'department')"></el-color-picker>
                      </b-col>
                      <b-col>
                        <el-color-picker :predefine="predefineColors" v-model="btnCToColor" size="small" @change="set_all_botton_color($event, 'category')"></el-color-picker>
                      </b-col>
                      <b-col>
                        <el-color-picker :predefine="predefineColors" v-model="btnPToColor" size="small" @change="set_all_botton_color($event, 'product')"></el-color-picker>
                      </b-col>
                    </b-row>
                 </div>
                </div>
              </b-row>
            </el-form>
          </b-card>
        </el-tab-pane>

        <el-tab-pane :label="$t('New Menu')" name="add">
          <b-card>
            <el-form ref="form">
              <b-row class="mb-1">
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Name') + '1' }}
                    </el-col>
                    <el-col :span="12">
                      <el-input
                        v-model="cur_menu.Name1"
                        size="mini"
                      ></el-input>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Name') + '2' }}
                    </el-col>
                    <el-col :span="12">
                      <el-input
                        v-model="cur_menu.Name2"
                        size="mini"
                      ></el-input>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Price Group') }}
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        size="mini"
                        v-model="cur_menu.PriceGroup"
                        placeholder="Price Group"
                      >
                        <el-option label="A" value="A"></el-option>
                        <el-option label="B" value="B"></el-option>
                        <el-option label="C" value="C"></el-option>
                        <el-option label="D" value="D"></el-option>
                        <el-option label="E" value="E"></el-option>
                        <el-option label="F" value="F"></el-option>
                        <el-option label="G" value="G"></el-option>
                        <el-option label="H" value="H"></el-option>
                        <el-option label="I" value="I"></el-option>
                        <el-option label="J" value="J"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Get All Products') }}
                    </el-col>
                    <el-col :span="12">
                      <el-switch
                        v-model="new_menu_get_all"
                        active-value="1"
                        inactive-value="0"
                      ></el-switch>
                    </el-col>
                  </el-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Department Rows') }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="cur_menu.DepartmentRow"
                        :min="1"
                        :max="9"
                        size="mini"
                      ></el-input-number>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Department Cols') }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="cur_menu.DepartmentColumn"
                        :min="1"
                        :max="9"
                        size="mini"
                      ></el-input-number>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Category Rows') }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="cur_menu.CategoryRow"
                        :min="1"
                        :max="9"
                        size="mini"
                      ></el-input-number>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Category Cols') }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="cur_menu.CategoryColumn"
                        :min="1"
                        :max="9"
                        size="mini"
                      ></el-input-number>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Product Rows') }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="cur_menu.ProductRow"
                        :min="1"
                        :max="9"
                        size="mini"
                      ></el-input-number>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Product Cols') }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="cur_menu.ProductColumn"
                        :min="1"
                        :max="9"
                        size="mini"
                      ></el-input-number>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col cols="9">
                  <el-row :gutter="20">
                    <el-col :span="4" class="text-right">
                      {{ $t('Store') }}
                    </el-col>
                    <el-col :span="20">
                      <el-checkbox-group
                        v-model="cur_menu.LocationID"
                        class="ml-2"
                      >
                        <el-checkbox
                          v-for="store in storelist"
                          :key="store.LocationID"
                          :label="store.LocationID"
                          >{{ store.LocationName }}</el-checkbox
                        >
                      </el-checkbox-group>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-button type="primary" @click="add_menu()" size="medium">{{ $t("Create") }}</el-button>
                    </el-col>
                  </el-row>
                </b-col>
              </b-row>
            </el-form>
          </b-card>
          <b-card :title="$t('Copy Menu')">
            <el-form ref="form">
              <b-row class="mb-1">
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Menu') }}
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        size="mini"
                        v-model="copy_Menu_IDn"
                      >
                        <el-option v-for="menu in menulist" :key="menu.IDn" :label="menu.Name1" :value="menu.IDn"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Name') + '1' }}
                    </el-col>
                    <el-col :span="12">
                      <el-input
                        v-model="copy_Name1"
                        size="mini"
                      ></el-input>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Name') + '2' }}
                    </el-col>
                    <el-col :span="12">
                      <el-input
                        v-model="copy_Name2"
                        size="mini"
                      ></el-input>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-button type="primary" @click="copy_menu()" size="medium">{{ $t("Copy") }}</el-button>
                    </el-col>
                  </el-row>
                </b-col>
              </b-row>
            </el-form>
          </b-card>
          <b-card v-if="pkadmin" :title="$t('Copy Price Group')">
            <el-form ref="form">
              <b-row class="mb-1">
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Price Group') }}
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        size="mini"
                        v-model="copy_PriceF"
                        placeholder="Price Group"
                      >
                        <el-option label="A" value="A"></el-option>
                        <el-option label="B" value="B"></el-option>
                        <el-option label="C" value="C"></el-option>
                        <el-option label="D" value="D"></el-option>
                        <el-option label="E" value="E"></el-option>
                        <el-option label="F" value="F"></el-option>
                        <el-option label="G" value="G"></el-option>
                        <el-option label="H" value="H"></el-option>
                        <el-option label="I" value="I"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      To
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        size="mini"
                        v-model="copy_PriceT"
                        placeholder="Price Group"
                      >
                        <el-option label="B" value="B"></el-option>
                        <el-option label="C" value="C"></el-option>
                        <el-option label="D" value="D"></el-option>
                        <el-option label="E" value="E"></el-option>
                        <el-option label="F" value="F"></el-option>
                        <el-option label="G" value="G"></el-option>
                        <el-option label="H" value="H"></el-option>
                        <el-option label="I" value="I"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-button type="primary" @click="copy_price()" size="medium">{{ $t("Copy") }}</el-button>
                    </el-col>
                  </el-row>
                </b-col>
              </b-row>
            </el-form>
          </b-card>
          <b-card :title="$t('Import')">
            <el-form ref="form">
              <b-row class="mb-1">
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="12" class="text-right">
                      {{ $t('Overwrite menu') }}
                    </el-col>
                    <el-col :span="12">
                      <el-switch
                        v-model="import_overwrite"
                        active-value="1"
                        inactive-value="0"
                      ></el-switch>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10" v-if="import_overwrite == 1">
                    <el-col :span="12" class="text-right">
                      {{ $t('Menu') }}
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        size="mini"
                        v-model="overwrite_Menu_IDn"
                      >
                        <el-option v-for="menu in menulist" :key="menu.IDn" :label="menu.Name1" :value="menu.IDn"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </b-col>
                <b-col cols="3">
                </b-col>
                <b-col cols="3">
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <input ref="fileuploader" type="file" @change="on_import_change" @click="reset_import_file"/>
                      <xlsx-read :file="import_file" @parsed="import_file_parsed">
                      </xlsx-read>
                    </el-col>
                  </el-row>
                </b-col>
              </b-row>
            </el-form>
          </b-card>
        </el-tab-pane>
      </el-tabs>
    </b-container>
    <el-dialog
      :title="cur_product.Name1 + ' - ' + cur_product.Name2"
      :visible.sync="product_modify"
      width="30%"
    >
      <!-- el-form>
        <b-row>
          <b-col>
            <div>{{ $t("Available") }} {{ $t("Modifier") }}</div>
            <div class="product_select">
              <el-tag
                type="info"
                v-for="prod in new_modifierlist"
                :key="prod.IDn"
                @click.native="add_modifier(prod.IDn)"
              >
                <span class="product">{{ prod.Name1 }}-{{ prod.Name2 }}</span>
              </el-tag>
            </div>
          </b-col>
          <b-col>
            <div>{{ $t("Existing") }} {{ $t("Modifier") }}</div>
            <div class="product_select">
              <el-tag
                closable
                type="danger"
                v-for="prod in cur_modifierlist"
                :key="prod.IDn"
                @close="delete_modifier(prod.IDn)"
                @click.native="delete_modifier(prod.IDn)"
              >
                <span class="product">{{ prod.Name1 }}-{{ prod.Name2 }}</span>
              </el-tag>
            </div>
          </b-col>
        </b-row>
      </el-form -->
      <el-form :inline="true">
        <b-row>
          <b-col class="text-right" cols="4">
            <label for="btnprice" class="">{{ $t("Price") }}</label>
          </b-col>
          <b-col>
            <input
              id="btnprice"
              class="form-control"
              v-model="curmenu_product.Price"
              autocomplete="off"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right" cols="4">
            <label for="btnprice" class="">{{ $t("Discount Price") }}</label>
          </b-col>
          <b-col>
            <input
              id="btnprice"
              class="form-control"
              v-model="curmenu_product.DiscountPrice"
              autocomplete="off"
            />
          </b-col>
        </b-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="product_modify = false">{{
          $t("Cancel")
        }}</el-button>
        <el-button type="primary" @click="product_submit">{{
          $t("Submit")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Upload Image File" :visible.sync="upload_product_dialog" width="30%">
      <div class="upload_product_div" id="upload_product_ref">
        <div v-for="(row,idx) in upload_file_list" :key="idx">{{ row }}</div>
      </div>
      <div slot="footer">
        <el-button v-if="upload_show_close" @click="upload_product_dialog = false">OK</el-button>
      </div>
    </el-dialog>
    <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import {
  Form,
  Tabs,
  TabPane,
  Button,
  Checkbox,
  CheckboxGroup,
  FormItem,
  Select,
  Option,
  InputNumber,
  Tag,
  Switch,
  Row,
  Dialog,
  Col,
  Radio,
  RadioGroup,
  ColorPicker,
  DatePicker,
} from "element-ui";
import "flatpickr/dist/flatpickr.css";
import MenuBtn from "../Components/MenuBtn";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";
import { XlsxRead, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

const axios = require("axios");

export default {
  components: {
    [Form.name]: Form,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [InputNumber.name]: InputNumber,
    [Tag.name]: Tag,
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Dialog.name]: Dialog,
    [Col.name]: Col,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [ColorPicker.name]: ColorPicker,
    [DatePicker.name]: DatePicker,
    MenuBtn,
    VueElementLoading,
    XlsxRead,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      showloading: false,
      panel_small: false,

      active_tab: "",
      product_modify: false,
      new_menu_get_all: 0,
      isDraft: 0,
      cur_idx: 1,

      currpage: "list",
      cur_opt: null,

      predefineColors: ['#DFB9FC','#EBAE6C','#6AE7E7','#FFE100','#00FE25','#F37F76','#43D5E2'],

      hasLdwUpload: false,
      upload_product_dialog: false,
      upload_file_ids: [],
      upload_file_cnt: 0,
      upload_file_list: [],
      upload_show_close: false,

      showLanguage: "1",
      fontSizeList: [{value:14,label:'14px'},{value:16,label:'16px'},{value:18,label:'18px'},{value:24,label:'24px'},{value:28,label:'28px'},{value:32,label:'32px'},],
      fontWeightList: [{value:400,label:'Normal'},{value:500,label:'Normal +'},{value:600,label:'Bold -'},{value:700,label:'Bold'},{value:800,label:'Bold +'},],
      cur_menu: {
        IDn: "",
        Name1: "",
        Name2: "",
        Remarks: "",
        PanelWidth: 674,
        PanelHeigh: 663,
        DepartmentHeight: 39,
        CategoryHeight: 117,
        ProductHeight: 507,
        DepartmentRow: 1,
        DepartmentColumn: 5,
        DepartmentFontSize: 14,
        DepartmentFontWeight: 400,
        DepartmentFontColor: '#000000',
        CategoryRow: 3,
        CategoryColumn: 5,
        CategoryFontSize: 14,
        CategoryFontWeight: 400,
        CategoryFontColor: '#000000',
        ProductRow: 5,
        ProductColumn: 5,
        ProductFontSize: 14,
        ProductFontWeight: 400,
        ProductFontColor: '#000000',
        PriceGroup: "A",
        LocationID: [],
        TaxGroupIDn: [],
        HourTable: "",
        IsOnline: 0,
        StartDate: "2021-01-01",
        EndDate: "2031-01-01",
        Status: 1,
      },
      new_menu: {
        IDn: "",
        Name1: "",
        Name2: "",
        Remarks: "",
        PanelWidth: 674,
        PanelHeigh: 663,
        DepartmentHeight: 39,
        CategoryHeight: 117,
        ProductHeight: 507,
        DepartmentRow: 1,
        DepartmentColumn: 5,
        DepartmentFontSize: 14,
        DepartmentFontWeight: 400,
        DepartmentFontColor: '#000000',
        CategoryRow: 3,
        CategoryColumn: 5,
        CategoryFontSize: 14,
        CategoryFontWeight: 400,
        CategoryFontColor: '#000000',
        ProductRow: 5,
        ProductColumn: 5,
        ProductFontSize: 14,
        ProductFontWeight: 400,
        ProductFontColor: '#000000',
        PriceGroup: "A",
        LocationID: [],
        TaxGroupIDn: [],
        HourTable: "",
        IsOnline: 0,
        StartDate: "2021-01-01",
        EndDate: "2031-01-01",
        Status: 1,
      },
      cur_product: {
        Name1: "",
        Name2: "",
      },
      curmenu_product: {
        Price: 0,
        DiscountPrice: 0,
        BtnColor: "#cad1d7",
        Quantity: 1000,
        SortOrder: 999,
        Soldout: 1,
      },
      menulist: null,
      departmentlist: null,
      curmenu_departmentlist: null,
      categorylist: null,
      curmenu_categorylist: null,
      storelist: null,
      statuses: [],
      curmenu_productlist: null,
      productlist: null,

      cur_modifierlist: null,
      new_modifierlist: null,

      cur_MenuIDn: 0,
      cur_DepartmentIDn: 0,
      cur_CategoryIDn: 0,
      cur_ProductIDn: 0,
      curmenu_ProductIDn: 0,

      cur_AltDepartmentIDn: 0,
      cur_AltCategoryIDn: 0,
      addOtherCategory: 0,

      copy_Name1: "",
      copy_Name2: "",
      copy_Menu_IDn: null,
      copy_PriceF: "A",
      copy_PriceT: "B",

      dismessage: "",
      countdown: 0,

      PanelWidth: 690,  // Add scroll bar 674 + 6
      PanelHeigh: 665,  // Add divider bar 663 + 2

      menuDeptBtnWidth: 151,
      menuDeptBtnHeight: 60,
      menuCateBtnWidth: 200,
      menuCateBtnHeight: 100,
      menuProdBtnWidth: 200,
      menuProdBtnHeight: 100,

      btnToColor: "#cad1d7",
      btnDToColor: "#EBAE6C",
      btnCToColor: "#43D5E2",
      btnPToColor: "#7BF58D",
      changeBtnColor: false,

      department_list_size: { width: '690px', height: '39px',},
      category_list_size: { width: '690px', height: '117px',},
      product_list_size: { width: '690px', height: '500px',},
      btnselectstyle: { backgroundColor: "#cad1d7" },
      panel_left: { width: '116px'},

      movingtype: null,
      movingevt: null,
      movingprod: null,
      movingleft: 0,
      movingtop: 0,

      sync_to_server: null,

      new_menu_department: {
        IDn: 0,
        Name1: "",
        Name2: "",
        MenuIDn: 0,
        DepartmentIDn: 0,
        Categorys: [],
        BtnColor: '#EBAE6C',
        SortOrder: 999,
      },
      new_menu_category: {
        IDn: 0,
        Name1: "",
        Name2: "",
        MenuIDn: 0,
        DepartmentIDn: 0,
        CategoryIDn: 0,
        Products: [],
        BtnColor: '#43D5E2',
        SortOrder: 999,
      },
      new_menu_product: {
        IDn: 0,
        Name1: "",
        Name2: "",
        MenuIDn: 0,
        DepartmentIDn: 0,
        CategoryIDn: 0,
        ProductIDn: 0,
        AltCategoryIDn: 0,
        BtnColor: '#7BF58D',
        SortOrder: 999,
      },

      pkadmin: false,

      token: "",
      user_id: 0,

      // export
      export_sheets_for_menu: [{ name: "menu", data: [[1,2,3,4],[5,6,7,8]] }],
      callback_for_menu: "",
      export_filename: "",
      // import
      import_file: null,
      import_overwrite: 0,
      overwrite_Menu_IDn: 0,
    };
  },
  computed: {},
  watch: {
    // cur_MenuIDn: function (nval, oval) {
    // },
  },
  methods: {
    cancel_change() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("MenuIDn", this.cur_MenuIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/menu/cancel_change",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_MenuIDn = rt.data.MenuIDn;
              that.cur_DepartmentIDn = 0;
              that.cur_CategoryIDn = 0;
              that.isDraft = 0;
              that.get_menu_list();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    save_change() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("MenuIDn", this.cur_MenuIDn);
      bodyFormData.append("Menu", JSON.stringify(this.cur_menu));
      bodyFormData.append("Departments", JSON.stringify(this.curmenu_departmentlist));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/menu/save_menu",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_MenuIDn = rt.data.MenuIDn;
              that.cur_DepartmentIDn = 0;
              that.cur_CategoryIDn = 0;
              that.getcur_menu();
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    // export
    change_for_menu(sheet, menu_id) {
      // change event will emit twice when sheets is set, xlsx sheets is set to empty at first event, then xlsx sheets is set to the new sheets.
      if (this.callback_for_menu && sheet.SheetNames && sheet.SheetNames.length > 0) {
        // if multiple tabs are activated, there will be multiple download components, and this function will be triggered redundently
        // so use the menu id to distinguish.
        if (menu_id == this.cur_MenuIDn) {
          this.callback_for_menu();
        }
      }
    },
    created_for_menu() {
      this.callback_for_menu = '';
    },
    export_menu(callback) {
      this.export_sheets_for_menu[0].data = this.generate_excel_data();
      this.callback_for_menu = callback;
    },
    generate_excel_data() {
      var today = new Date();
      let todaystr = today.getFullYear() + '_' + String(today.getMonth() + 1).padStart(2, '0') + '_' + String(today.getDate()).padStart(2, '0');
      this.export_filename = this.cur_menu.Name1 + '_' + this.cur_menu.Name2 + '_' + todaystr;
      let excel_data = [];
      excel_data.push(["Menu", JSON.stringify(this.cur_menu)]);
      excel_data.push(["Departments", JSON.stringify(this.curmenu_departmentlist)]);
      return excel_data;
    },
    // import
    on_import_change(event) {
      this.import_file = event.target.files ? event.target.files[0] : null;
    },
    reset_import_file() {
      this.import_file = null;
      this.$refs.fileuploader.value = '';
    },
    import_file_parsed(filedata) {
      this.reset_import_file();

      if (!confirm("Are you sure to import the file?")) {
        return;
      }

      if (this.import_overwrite == 1 && this.overwrite_Menu_IDn == 0) {
        alert("Please select menu to overwrite");
        return;
      }

      this.selected_product_list = [];
      var sheets = filedata.Sheets[Object.keys(filedata.Sheets)[0]];
      var A1 = sheets["A1"] != undefined ? sheets["A1"].w.trim() : "";
      var B1 = sheets["B1"] != undefined ? sheets["B1"].w.trim() : "";   // menu
      var A2 = sheets["A2"] != undefined ? sheets["A2"].w.trim() : "";
      var B2 = sheets["B2"] != undefined ? sheets["B2"].w.trim() : "";   // Departments

      if (A1 != "Menu" || A2 != "Departments") {
        alert("Wrong file");
        return;
      }

      try {
        JSON.parse(B1);
        JSON.parse(B2);
      } catch (err) {
        alert("File content wrong");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("Menu", B1);
      bodyFormData.append("Departments", B2);
      bodyFormData.append("overwrite_Menu_IDn", this.import_overwrite == 1 ? this.overwrite_Menu_IDn : 0);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/menu/import_menu",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_MenuIDn = rt.data.MenuIDn;
              that.cur_DepartmentIDn = 0;
              that.cur_CategoryIDn = 0;
              that.get_menu_list();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    remove_cur_department(ev, dpt) {
      ev.preventDefault();
      if (dpt.Categorys && dpt.Categorys.length > 0) {
        alert("Must Remove All Sub-Category First!")
        return;
      }
      
      var idx = this.find_cur_department(dpt.DepartmentIDn);
      if (idx < 0) {
        alert("Some thing wrong, can't find department!")
        return;
      }
      this.curmenu_departmentlist.splice(idx,1);
      this.change_cur_menu();
      idx = this.find_department(dpt.DepartmentIDn);
      if (idx < 0) {
        return;
      }
      this.departmentlist[idx].show = 1;
    },
    remove_cur_category(ev, cat) {
      ev.preventDefault();
      if (cat.Products && cat.Products.length > 0) {
        alert("Must Remove All Sub-Product First!")
        return;
      }
      var dptidx = this.find_cur_department(cat.DepartmentIDn);
      if (dptidx < 0) {
        alert("Some thing wrong, can't find department!")
        return;
      }
      var idx = this.find_cur_category(this.curmenu_departmentlist[dptidx].Categorys, cat.CategoryIDn);
      if (idx < 0) {
        alert("Some thing wrong, can't find category!")
        return;
      }
      this.curmenu_departmentlist[dptidx].Categorys.splice(idx,1);
      this.change_cur_menu();

      dptidx = this.find_department(cat.DepartmentIDn);
      if (dptidx < 0) {
        return;
      }
      idx = this.find_category(this.departmentlist[dptidx].Categorys, cat.CategoryIDn);
      if (idx < 0) {
        return;
      }
      this.departmentlist[dptidx].Categorys[idx].show = 1;
    },
    remove_cur_product(ev, prd) {
      ev.preventDefault();

      var dptidx = this.find_cur_department(prd.DepartmentIDn);
      if (dptidx < 0) {
        alert("Some thing wrong, can't find department!")
        return;
      }
      var catidx = this.find_cur_category(this.curmenu_departmentlist[dptidx].Categorys, prd.CategoryIDn);
      if (catidx < 0) {
        alert("Some thing wrong, can't find category!")
        return;
      }
      var idx = this.find_cur_product(this.curmenu_departmentlist[dptidx].Categorys[catidx].Products, prd.ProductIDn);
      if (idx < 0) {
        alert("Some thing wrong, can't find product!")
        return;
      }
      this.curmenu_departmentlist[dptidx].Categorys[catidx].Products.splice(idx,1);
      this.change_cur_menu();
      if (prd.AltCategoryIDn == prd.CategoryIDn) {
        var product = this.fullfind_product(prd.ProductIDn);
        if (product) {
          product.show = 1;
        }
      }
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    add_other_category() {
      if (this.addOtherCategory) {
        this.cur_AltDepartmentIDn = this.cur_DepartmentIDn;
        this.cur_AltCategoryIDn = this.cur_CategoryIDn;
      } else {
        this.cur_DepartmentIDn = this.cur_AltDepartmentIDn;
        this.cur_CategoryIDn = this.cur_AltCategoryIDn;
        this.cur_AltDepartmentIDn = 0;
        this.cur_AltCategoryIDn = 0;
      }
    },
    resize_buttons() {
      // var curmenu_departmentlist = this.curmenu_departmentlist; 
      // var curmenu_categorylist = this.curmenu_categorylist; 
      // var curmenu_productlist = this.curmenu_productlist; 
      // this.curmenu_departmentlist = null;
      // this.curmenu_categorylist = null;
      // this.curmenu_productlist = null;

      if (this.cur_MenuIDn > 0) {
        this.menuDeptBtnWidth = Math.floor(this.cur_menu.PanelWidth / this.cur_menu.DepartmentColumn);
        this.menuDeptBtnHeight = Math.floor(this.cur_menu.DepartmentHeight / this.cur_menu.DepartmentRow);
        this.menuCateBtnWidth = Math.floor(this.cur_menu.PanelWidth / this.cur_menu.CategoryColumn);
        this.menuCateBtnHeight = Math.floor(this.cur_menu.CategoryHeight / this.cur_menu.CategoryRow);
        this.menuProdBtnWidth = Math.floor(this.cur_menu.PanelWidth / this.cur_menu.ProductColumn);
        this.menuProdBtnHeight = Math.floor(this.cur_menu.ProductHeight / this.cur_menu.ProductRow);
        this.department_list_size.height = (this.cur_menu.DepartmentHeight) + 'px';
        this.category_list_size.height = (this.cur_menu.CategoryHeight) + 'px';
        this.product_list_size.height = (this.cur_menu.ProductHeight) + 'px';
      } else {
        this.department_list_size.height = this.new_menu.DepartmentHeight + 'px';
        this.category_list_size.height = this.new_menu.CategoryHeight + 'px';
        this.product_list_size.height = this.new_menu.ProductHeight + 'px';
      }

      this.set_menu_department_idx();
      this.set_menu_category_idx();
      this.set_menu_product_idx();
      // this.$nextTick(() => {
      //   this.curmenu_departmentlist = curmenu_departmentlist; 
      //   this.curmenu_categorylist = curmenu_categorylist; 
      //   this.curmenu_productlist = curmenu_productlist; 
      // });
    },

    get_variant(IDn, what) {
      if (what == "department") {
        if (this.cur_DepartmentIDn == IDn) {
          return "outline-warning";
        }
      } else if (what == "category") {
        if (this.cur_CategoryIDn == IDn) {
          return "outline-warning";
        }
      } else if (what == "product") {
        if (this.cur_ProductIDn == IDn) {
          return "outline-warning";
        }
      }
      return "outline-primary";
    },
    select_product(IDn, what) {
      if (what == "department") {
        this.cur_DepartmentIDn = IDn;
        // Left menu
        var midx = this.find_department(this.cur_DepartmentIDn);
        if (midx < 0) {
          alter("Department data error");
          this.cur_DepartmentIDn = 0;
          return;
        }
        this.categorylist = this.departmentlist[midx].Categorys;
        // Right menu
        if (!this.addOtherCategory) {
          var idx = this.find_cur_department(this.cur_DepartmentIDn);
          if (idx < 0) {
            // There is this depeartment data available
            this.curmenu_categorylist = [];
            this.curmenu_productlist = [];
            this.cur_CategoryIDn = 0;
            this.cur_ProductIDn = 0;
            return;
          }
          this.curmenu_categorylist = this.curmenu_departmentlist[idx].Categorys;
        }
        if (this.categorylist.length > 0) {
          this.cur_CategoryIDn = this.categorylist[0].IDn;
        } else {
          this.cur_CategoryIDn = 0;
          return;
        }
        // Left menu
        var midx1 = this.find_category(this.categorylist, this.cur_CategoryIDn);
        if (midx1 < 0) {
          alter("Category data error");
          this.cur_CategoryIDn = 0;
          return;
        }
        this.productlist = this.categorylist[midx1].Products;
        // Right menu
        if (!this.addOtherCategory) {
          var idx1 = this.find_cur_category(this.curmenu_categorylist, this.cur_CategoryIDn);
          if (idx1 < 0) {
            this.curmenu_productlist = [];
            this.cur_ProductIDn = 0;
            return;
          }
          this.curmenu_productlist = this.curmenu_categorylist[idx1].Products;

        }
        if (this.productlist.length > 0) {
          this.cur_ProductIDn = this.productlist[0].IDn;
        } else {
          this.cur_ProductIDn = 0;
        }
      } else if (what == "category") {
        this.cur_CategoryIDn = IDn;
        // Left menu
        var midx1 = this.find_category(this.categorylist, this.cur_CategoryIDn);
        if (midx1 < 0) {
          this.cur_CategoryIDn = 0;
          return;
        }
        this.productlist = this.categorylist[midx1].Products;
        if (!this.addOtherCategory) {
          // Right menu
          var idx1 = this.find_cur_category(this.curmenu_categorylist, this.cur_CategoryIDn);
          if (idx1 < 0) {
            this.curmenu_productlist = [];
            this.cur_ProductIDn = 0;
            return;
          }
          this.curmenu_productlist = this.curmenu_categorylist[idx1].Products;
        }
        if (this.productlist.length > 0) {
          this.cur_ProductIDn = this.productlist[0].IDn;
          for (var i = 0; i < this.productlist.length; i++) {
            var idx1 = this.find_cur_product(this.curmenu_productlist, this.productlist[i].IDn);
            if (idx1 < 0) {
              this.productlist[i].show = 1;
            } else {
              this.productlist[i].show = 0;
            }
          }
        } else {
          this.cur_ProductIDn = 0;
        }
        if (this.addOtherCategory) {
        }
      } else if (what == "product") {
        this.cur_ProductIDn = IDn;
        if (this.productlist.length > 0) {
          for (var i = 0; i < this.productlist.length; i++) {
            var idx1 = this.find_cur_product(this.curmenu_productlist, this.productlist[i].IDn);
            if (idx1 < 0) {
              this.productlist[i].show = 1;
            } else {
              this.productlist[i].show = 0;
            }
          }
        }
      }
    },
    // Add product/category/department to right
    add_product(IDn, what, update=true) {
      // Add a product from
      if (what == "department") {
        var didx = this.find_department(IDn);
        if (didx < 0) {
          alert("Department data error");
          return;
        }
        var dpt = this.departmentlist[didx];
        var mdidx = this.find_cur_department(IDn);
        if (mdidx >= 0) {
          // Already add
          return;
        }
        dpt.show = 0;
        var mdpt = JSON.parse(JSON.stringify(this.new_menu_department));
        mdpt.Name1 = dpt.Name1;
        mdpt.Name2 = dpt.Name2;
        mdpt.MenuIDn = this.cur_MenuIDn;
        mdpt.DepartmentIDn = dpt.IDn;
        mdpt.BtnColor = this.btnDToColor;
        mdpt.SortOrder = this.curmenu_departmentlist.length + 1;
        mdpt.idx = this.cur_idx++;

        for (var i = 0; i < dpt.Categorys.length; i++) {
          var category = dpt.Categorys[i];
          category.show = 0;
          var mcat = JSON.parse(JSON.stringify(this.new_menu_category));
          mcat.Name1 = category.Name1;
          mcat.Name2 = category.Name2;
          mcat.MenuIDn = this.cur_MenuIDn;
          mcat.DepartmentIDn = category.DepartmentIDn;
          mcat.CategoryIDn = category.IDn;
          mcat.BtnColor = this.btnCToColor;
          mcat.SortOrder = i + 1;
          mcat.idx = this.cur_idx++;

          for (var j = 0; j < category.Products.length; j++) {
            var me = category.Products[j];
            me.show = 0;
            var mprd = JSON.parse(JSON.stringify(this.new_menu_product));
            mprd.Name1 = me.Name1;
            mprd.Name2 = me.Name2;
            mprd.MenuIDn = this.cur_MenuIDn;
            mprd.DepartmentIDn = me.DepartmentIDn;
            mprd.CategoryIDn = me.CategoryIDn;
            mprd.ProductIDn = me.IDn;
            mprd.AltCategoryIDn = me.CategoryIDn;
            mprd.BtnColor = this.btnPToColor;
            mprd.SortOrder = j + 1;
            mprd.idx = this.cur_idx++;

            mcat.Products.push(mprd);
          }
          mdpt.Categorys.push(mcat);
        }
        this.curmenu_departmentlist.push(mdpt);
      } if (what == "category") {
        var cidx = this.find_category(this.categorylist, IDn);
        if (cidx < 0) {
          alert("Category data error");
          return;
        }
        var category = this.categorylist[cidx];
        var mcidx = this.find_cur_category(this.curmenu_categorylist, IDn);
        if (mcidx >= 0) {
          // Already add
          return;
        }
        category.show = 0;
        var mcat = JSON.parse(JSON.stringify(this.new_menu_category));
        mcat.Name1 = category.Name1;
        mcat.Name2 = category.Name2;
        mcat.MenuIDn = this.cur_MenuIDn;
        mcat.DepartmentIDn = category.DepartmentIDn;
        mcat.CategoryIDn = category.IDn;
        mcat.BtnColor = this.btnCToColor;
        mcat.SortOrder = this.curmenu_categorylist.length + 1;
        mcat.idx = this.cur_idx++;
        for (var j = 0; j < this.categorylist[cidx].Products.length; j++) {
          var me = this.categorylist[cidx].Products[j];
          me.show = 0;
          var mprd = JSON.parse(JSON.stringify(this.new_menu_product));
          mprd.Name1 = me.Name1;
          mprd.Name2 = me.Name2;
          mprd.MenuIDn = this.cur_MenuIDn;
          mprd.DepartmentIDn = me.DepartmentIDn;
          mprd.CategoryIDn = me.CategoryIDn;
          mprd.ProductIDn = me.IDn;
          mprd.AltCategoryIDn = me.CategoryIDn;
          mprd.BtnColor = this.btnPToColor;
          mprd.SortOrder = j + 1;
          mprd.idx = this.cur_idx++;
          mcat.Products.push(mprd);
        }
        this.curmenu_categorylist.push(mcat);
      } if (what == "product") {
        var idx = this.find_product(this.productlist, IDn);
        if (idx < 0) {
          alert("Product data error");
          return;
        }
        var me = this.productlist[idx];
        var pidx = this.find_cur_product(this.curmenu_productlist, IDn);
        if (pidx >= 0) {
          // Already add
          return;
        }
        me.show = 0;
        var mprd = JSON.parse(JSON.stringify(this.new_menu_product));
        mprd.Name1 = me.Name1;
        mprd.Name2 = me.Name2;
        mprd.MenuIDn = this.cur_MenuIDn;
        if (this.addOtherCategory) {
          mprd.DepartmentIDn = this.cur_AltDepartmentIDn;
          mprd.CategoryIDn = this.cur_AltCategoryIDn;
        } else {
          mprd.DepartmentIDn = me.DepartmentIDn;
          mprd.CategoryIDn = me.CategoryIDn;
        }
        mprd.ProductIDn = me.IDn;
        mprd.AltCategoryIDn = me.CategoryIDn;
        mprd.BtnColor = this.btnPToColor;
        mprd.SortOrder = this.curmenu_productlist.length + 1;
        mprd.idx = this.cur_idx++;
        this.curmenu_productlist.push(mprd);
      }
      if (update) {
        this.select_product(IDn, what)
        this.change_cur_menu();
      }
      // this.product_change("add", IDn, what);
    },
    allowDrop(ev) {
      ev.preventDefault();
      // console.log("allowDrop", ev);
    },
    drag(ev, prod, movingtype) {
      this.movingtype = movingtype; // product / category
      this.movingevt = ev;
      this.movingprod = prod;
      this.movingleft = ev.screenX;
      this.movingtop = ev.screenY;
      // console.log("drag1", ev);
    },
    dropdiv(ev) {
      var offy = parseInt(ev.screenY - this.movingtop);
      this.cur_menu.DepartmentHeight = parseInt(this.cur_menu.DepartmentHeight);
      this.cur_menu.CategoryHeight = parseInt(this.cur_menu.CategoryHeight);
      this.cur_menu.ProductHeight = parseInt(this.cur_menu.ProductHeight);
      this.cur_menu.PanelHeigh = parseInt(this.cur_menu.PanelHeigh);
      if (this.movingtype == "dept_cate") {
        if (offy < 0) {
          // move up
          if ((offy + this.cur_menu.DepartmentHeight) < 0) {
            offy = -this.cur_menu.DepartmentHeight;
            this.cur_menu.DepartmentHeight = 0;
          } else {
            this.cur_menu.DepartmentHeight += offy;
          }
        } else {
          // move down
          if (offy > this.cur_menu.CategoryHeight) {
            offy = this.cur_menu.CategoryHeight;
          }
          this.cur_menu.DepartmentHeight += offy;
        }
        this.cur_menu.CategoryHeight -= offy;
        // console.log("dept_cate", offy);
        var adjy = this.cur_menu.DepartmentHeight + this.cur_menu.CategoryHeight + this.cur_menu.ProductHeight;
        if (this.cur_menu.PanelHeigh < adjy) {
          this.cur_menu.CategoryHeight -= adjy - this.cur_menu.PanelHeigh;
        }
        this.change_cur_menu();
      } else if (this.movingtype == "cate_prod") {
        if (offy < 0) {
          // move up
          if ((offy + this.cur_menu.CategoryHeight) < 0) {
            offy = -this.cur_menu.CategoryHeight;
            this.cur_menu.CategoryHeight = 0;
          } else {
            this.cur_menu.CategoryHeight += offy;
          }
        } else {
          // move down
          if (offy > this.cur_menu.ProductHeight) {
            offy = this.cur_menu.ProductHeight;
          }
          this.cur_menu.CategoryHeight += offy;
        }
        this.cur_menu.ProductHeight -= offy;
        // console.log("cate_prod", offy);
        var adjy = this.cur_menu.DepartmentHeight + this.cur_menu.CategoryHeight + this.cur_menu.ProductHeight;
        if (this.cur_menu.PanelHeigh < adjy) {
          this.cur_menu.ProductHeight -= adjy - this.cur_menu.PanelHeigh;
        }
        this.change_cur_menu();
      }
    },
    drop(ev) {
      ev.preventDefault();
      // console.log("drop", ev);
      if ((this.movingtype == "dept_cate") || (this.movingtype == "cate_prod")) {
        return this.dropdiv(ev);
      }

      this.showloading = true;
      var btnw, btnh, btncolumn;
      let newlist = [];
      let middlelist = [];
      var curlist, len;
      if (this.movingtype == "department") {
        btnw = this.menuDeptBtnWidth;
        btnh = this.menuDeptBtnHeight;
        btncolumn = this.cur_menu.DepartmentColumn;

        curlist = this.curmenu_departmentlist;
        len = this.curmenu_departmentlist.length;
      } else if (this.movingtype == "category") {
        btnw = this.menuCateBtnWidth;
        btnh = this.menuCateBtnHeight;
        btncolumn = this.cur_menu.CategoryColumn;

        curlist = this.curmenu_categorylist;
        len = this.curmenu_categorylist.length;
      } else if (this.movingtype == "product") {
        btnw = this.menuProdBtnWidth;
        btnh = this.menuProdBtnHeight;
        btncolumn = this.cur_menu.ProductColumn;

        curlist = this.curmenu_productlist;
        len = this.curmenu_productlist.length;
      } else {
        ; // console.log("drop event with unknown type: ", this.movingtype);
      }
      var offx = parseInt((ev.screenX - this.movingleft) / btnw);
      var offy = parseInt((ev.screenY - this.movingtop) / btnh);
      var curcol = (this.movingprod.SortOrder - 1) % btncolumn;
      var currow = parseInt((this.movingprod.SortOrder - 1) / btncolumn);
      var newcol = curcol + offx;
      var newrow = currow + offy;
      if (newcol < 0) {
        newcol = 0;
      } else if (newcol >= btncolumn) {
        newcol = btncolumn - 1;
      }
      if (newrow < 0) {
        newrow = 0;
      }

      let neworder = newrow * btncolumn + newcol;
      let curorder = this.movingprod.SortOrder - 1;

      for (var i = 0; i < len; i++) {
        if (i == curorder) {
          continue;
        }
        middlelist.push(curlist[i]);
      }

      var orderidx = 1;
      for (var i = 0; i < len - 1; i++) {
        if (i == neworder) {
          curlist[curorder].SortOrder = orderidx++;
          newlist.push(curlist[curorder]);
        }
        middlelist[i].SortOrder = orderidx++;
        newlist.push(middlelist[i]);
      }
      if (neworder >= len - 1) {
        // push if it is last one
        curlist[curorder].SortOrder = orderidx++;
        newlist.push(curlist[curorder]);
      }
      if (this.movingtype == "department") {
        this.curmenu_departmentlist = newlist;
      } else if (this.movingtype == "category") {
        var ci = this.find_cur_department(this.cur_DepartmentIDn);
        this.curmenu_departmentlist[ci].Categorys = newlist;
        this.curmenu_categorylist = newlist;
      } else if (this.movingtype == "product") {
        var pi = this.find_cur_category(this.curmenu_categorylist, this.cur_CategoryIDn);
        this.curmenu_categorylist[pi].Products = newlist;
        this.curmenu_productlist = newlist;
      } else {
        ; // console.log("drop event with unknown type: ", this.movingtype);
      }
      this.change_cur_menu();
    },
    set_all_botton_color(ev, whattodo) {
      if (ev && (ev.target != undefined)) {
        ev.preventDefault();
      }
      if (whattodo == 'all') {
        if (!this.changeBtnColor) {
          return;
        }
        for (var i = 0; i < this.curmenu_departmentlist.length; i++) {
          var dpt = this.curmenu_departmentlist[i];
          dpt.BtnColor = this.btnToColor;
          for (var j = 0; j < dpt.Categorys.length; j++) {
            var cat = dpt[j];
            cat.BtnColor = this.btnToColor;
            for (var k = 0; k < cat.Products.length; k++) {
              var cat = cat[k];
              cat.BtnColor = this.btnToColor;
            }
          }
        }
        this.set_menu_department_idx();
        this.set_menu_category_idx();
        this.set_menu_product_idx();
      } else if (whattodo == 'department') {
        let newdlist = this.curmenu_departmentlist;
        for (var i = 0; i < newdlist.length; i++) {
          newdlist[i].BtnColor = this.btnDToColor;
        }
        this.set_menu_department_idx();
      } else if (whattodo == 'category') {
        let newclist = this.curmenu_categorylist;
        for (var i=0; i<newclist.length; i++) {
          newclist[i].BtnColor = this.btnCToColor;
        }
        this.set_menu_category_idx();
      } else if (whattodo == 'product') {
        let newplist = this.curmenu_productlist;
        for (var i=0; i<newplist.length; i++) {
          newplist[i].BtnColor = this.btnPToColor;
        }
        this.set_menu_product_idx();
      }
      this.change_cur_menu();
    },
    edit_department(ev, prod) {
      ev.preventDefault();
      if (!this.changeBtnColor) {
        this.select_product(prod.DepartmentIDn, 'department')
        return;
      }
      prod.BtnColor = this.btnToColor;
      this.set_menu_department_idx();
      // let newlist = this.curmenu_departmentlist;
      // this.curmenu_departmentlist = null;
      // this.$nextTick(() => {
      //   // Add the component back in
      //   this.curmenu_departmentlist = newlist;
      // });
      this.change_cur_menu();
      // return this.update_color(prod.IDn, 'department');
    },
    edit_category(ev, prod) {
      ev.preventDefault();
      if (!this.changeBtnColor) {
        this.select_product(prod.CategoryIDn, 'category')
        return;
      }
      prod.BtnColor = this.btnToColor;
      this.set_menu_category_idx();
      // let newlist = this.curmenu_categorylist;
      // this.curmenu_categorylist = null;
      // this.$nextTick(() => {
      //   // Add the component back in
      //   this.curmenu_categorylist = newlist;
      // });
      this.change_cur_menu();
      // return this.update_color(prod.IDn, 'category');
    },
    edit_product(ev, prod) {
      ev.preventDefault();
      if (this.changeBtnColor) {
        prod.BtnColor = this.btnToColor;
        this.set_menu_product_idx();
        this.change_cur_menu();
        // return this.update_color(prod.IDn, 'product');
      } else {
        this.select_product(prod.ProductIDn, 'product')
      }
    },
    set_categorylist(deptIDn) {
      for (var i = 0; i < this.departmentlist.length; i++) {
        if (this.departmentlist[i].IDn == deptIDn) {
          this.categorylist = this.departmentlist[i];

        }
      }
    },
    format_meun(menu) {
      menu.PanelWidth = parseInt(menu.PanelWidth);
      menu.PanelHeigh = parseInt(menu.PanelHeigh);
      menu.DepartmentHeight = parseInt(menu.DepartmentHeight);
      menu.CategoryHeight = parseInt(menu.CategoryHeight);
      menu.ProductHeight = parseInt(menu.ProductHeight);
      menu.DepartmentRow = parseInt(menu.DepartmentRow);
      menu.DepartmentColumn = parseInt(menu.DepartmentColumn);
      menu.DepartmentFontSize = parseInt(menu.DepartmentFontSize);
      menu.DepartmentFontWeight = parseInt(menu.DepartmentFontWeight);
      menu.CategoryRow = parseInt(menu.CategoryRow);
      menu.CategoryColumn = parseInt(menu.CategoryColumn);
      menu.CategoryFontSize = parseInt(menu.CategoryFontSize);
      menu.CategoryFontWeight = parseInt(menu.CategoryFontWeight);
      menu.ProductRow = parseInt(menu.ProductRow);
      menu.ProductColumn = parseInt(menu.ProductColumn);
      menu.ProductFontSize = parseInt(menu.ProductFontSize);
      menu.ProductFontWeight = parseInt(menu.ProductFontWeight);
      menu.IsOnline = parseInt(menu.IsOnline);
    },
    async getcur_menu() {
      this.showloading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("MenuIDn", this.cur_MenuIDn);
      // Force redraw
      // this.cur_menu = JSON.parse(JSON.stringify(this.new_menu));
      // this.isDraft = 0;
      // this.menu_departments = null;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Menu/get_curent_menu",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_MenuIDn = rt.data.MenuIDn;
              var lt = typeof rt.data.menu.LocationID;
              if (lt != 'object') {
                rt.data.menu.LocationID = [];
              }
              that.cur_menu = rt.data.menu;
              that.format_meun(that.cur_menu);
              that.isDraft = rt.data.isDraft;
              var tmpmenu = rt.data.menu_departments;
              if (tmpmenu.length > 0) {
                for (var i = 0; i < tmpmenu.length; i++) {
                  tmpmenu[i].idx = that.cur_idx++;
                  for (var j = 0; j < tmpmenu[i].Categorys.length; j++) {
                    tmpmenu[i].Categorys[j].idx = that.cur_idx++;
                    for (var k = 0; k < tmpmenu[i].Categorys[j].Products.length; k++) {
                      tmpmenu[i].Categorys[j].Products[k].idx = that.cur_idx++;
                    }
                  }
                }
              }
              that.curmenu_departmentlist = tmpmenu;
              that.setcur_menu();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    find_department(dptIDn) {
      for (var i = 0; i < this.departmentlist.length; i++) {
        if (this.departmentlist[i].IDn == dptIDn) {
          return i;
        }
      }
      return -1;
    },
    find_category(cats, catIDn) {
      if (!cats) {
        return -1;
      }
      for (var i = 0; i < cats.length; i++) {
        if (cats[i].IDn == catIDn) {
          return i;
        }
      }
      return -1;
    },
    find_product(prds, prdIDn) {
      if (!prds) {
        return -1;
      }
      for (var i = 0; i < prds.length; i++) {
        if (prds[i].IDn == prdIDn) {
          return i;
        }
      }
      return -1;
    },
    find_cur_department(dptIDn) {
      for (var i = 0; i < this.curmenu_departmentlist.length; i++) {
        if (this.curmenu_departmentlist[i].DepartmentIDn == dptIDn) {
          return i;
        }
      }
      return -1;
    },
    find_cur_category(cats, catIDn) {
      if (!cats) {
        return -1;
      }
      for (var i = 0; i < cats.length; i++) {
        if (cats[i].CategoryIDn == catIDn) {
          return i;
        }
      }
      return -1;
    },
    find_cur_product(prds, prdIDn) {
      if (!prds) {
        return -1;
      }
      for (var i = 0; i < prds.length; i++) {
        if (prds[i].ProductIDn == prdIDn) {
          return i;
        }
      }
      return -1;
    },
    // Find product that come from other category
    fullfind_product(prdIDn) {
      for (var i = 0; i < this.departmentlist.length; i++) {
        for (var j = 0; j < this.departmentlist[i].Categorys.length; j++) {
          for (var k = 0; k < this.departmentlist[i].Categorys[j].Products.length; k++) {
            if (this.departmentlist[i].Categorys[j].Products[k].IDn == prdIDn) {
              return this.departmentlist[i].Categorys[j].Products[k];
            }
          }
        }
      }
      return false;
    },
    setcur_menu() {
      if (this.cur_MenuIDn > 0) {
        this.active_tab = this.cur_MenuIDn + "tab";
      } else {
        // Add New Menu
        this.active_tab = "add";
        this.cur_MenuIDn = 0;
        this.new_menu_get_all = 0;
        this.cur_menu = JSON.parse(JSON.stringify(this.new_menu));
        return;
      }

      // Reset left product list
      this.cur_DepartmentIDn = this.departmentlist[0].IDn;
      this.cur_CategoryIDn = this.departmentlist[0]["Categorys"][0].IDn;
      this.categorylist = this.departmentlist[0]["Categorys"];
      this.productlist = this.departmentlist[0]["Categorys"][0]["Products"];

      for (var di = 0; di < this.departmentlist.length; di++) {
        this.departmentlist[di].show = 1;
        for (var ci = 0; ci < this.departmentlist[di].Categorys.length; ci++) {
          this.departmentlist[di].Categorys[ci].show = 1;
          for (var pi = 0; pi < this.departmentlist[di].Categorys[ci].Products.length; pi++) {
            var prd = this.departmentlist[di].Categorys[ci].Products[pi].show = 1;
          }
        }
      }

      // Remove one menu product from left list
      var curmenu_departmentlist = [], curmenu_categorylist = [], curmenu_productlist = []; 
      curmenu_departmentlist = this.curmenu_departmentlist;
      for (var dmi = 0; dmi < this.curmenu_departmentlist.length; dmi++) {
        var mdpt = this.curmenu_departmentlist[dmi];
        var di = this.find_department(mdpt.DepartmentIDn);
        if (di < 0) {
          this.dismessage = "Menu can't find correstponding department, please contact support. MenuID:" + this.cur_MenuIDn + "; DptID:" + mdpt.DepartmentIDn;
          this.countdown = 20;
          return;
        }
        this.departmentlist[di].show = 0;
        mdpt.Name1 = this.departmentlist[di].Name1;
        mdpt.Name2 = this.departmentlist[di].Name2;
        if (this.cur_DepartmentIDn == mdpt.DepartmentIDn) {
          curmenu_categorylist = this.curmenu_departmentlist[dmi].Categorys;
        }
        for (var cmi = 0; cmi < this.curmenu_departmentlist[dmi].Categorys.length; cmi++) {
          var mcat = this.curmenu_departmentlist[dmi].Categorys[cmi];
          var ci = this.find_category(this.departmentlist[di].Categorys, mcat.CategoryIDn);
          if (ci < 0) {
            this.dismessage = "Menu can't find correstponding category, please contact support. MenuID:" + this.cur_MenuIDn + "; DptID:" + mdpt.DepartmentIDn + "; CatID:" + mcat.CategoryIDn;
            this.countdown = 20;
            return;
          }
          this.departmentlist[di].Categorys[ci].show = 0;
          mcat.Name1 = this.departmentlist[di].Categorys[ci].Name1;
          mcat.Name2 = this.departmentlist[di].Categorys[ci].Name2;
          if (this.cur_CategoryIDn == mcat.CategoryIDn) {
            curmenu_productlist = this.curmenu_departmentlist[dmi].Categorys[cmi].Products;
          }
          for (var pmi = this.curmenu_departmentlist[dmi].Categorys[cmi].Products.length - 1; pmi >= 0; pmi--) {
            var mprd = this.curmenu_departmentlist[dmi].Categorys[cmi].Products[pmi];
            var pi = this.find_product(this.departmentlist[di].Categorys[ci].Products, mprd.ProductIDn);
            if (pi < 0) {
              // Maybe from other category
              var tprd = this.fullfind_product(mprd.ProductIDn);
              if (tprd) {
                mprd.Name1 = tprd.Name1;
                mprd.Name2 = tprd.Name2;
              }
              continue;
            }
            if (this.departmentlist[di].Categorys[ci].Products[pi].Status == "Deactive") {
              this.curmenu_departmentlist[dmi].Categorys[cmi].Products.splice(pmi,1);
              continue;
            }

            this.departmentlist[di].Categorys[ci].Products[pi].show = 0;
            mprd.Name1 = this.departmentlist[di].Categorys[ci].Products[pi].Name1;
            mprd.Name2 = this.departmentlist[di].Categorys[ci].Products[pi].Name2;
          }
        }
      }
      this.curmenu_departmentlist = curmenu_departmentlist; 
      this.curmenu_categorylist = curmenu_categorylist; 
      this.curmenu_productlist = curmenu_productlist; 
      this.resize_buttons();
    },
    check_changed_tabs(newname, oldname) {
      if (this.isDraft && (oldname != undefined) && (oldname != 0) && (oldname != 'add') ) {
        this.dismessage = "Please finish current editing Menu or cancel all changes";
        this.countdown = 3;
        return false;
      }
      return true;
    },
    changed_tabs(tab, event) {
      this.addOtherCategory = 0;
      this.cur_DepartmentIDn = 0;
      this.cur_CategoryIDn = 0;
      if (this.active_tab != "add") {
        this.cur_MenuIDn = parseInt(this.active_tab);
        this.getcur_menu();
      } else {
        this.cur_MenuIDn = 0;
        this.cur_menu = JSON.parse(JSON.stringify(this.new_menu));
      }
    },
    colorbtn(idx) {
    },
    add_all(what) {
      // department, category, product
      var products = "";
      if (what == "department") {
        this.curmenu_departmentlist = [];
        for (var i = 0; i < this.departmentlist.length; i++) {
          this.add_product(this.departmentlist[i].IDn, what, false);
        }
        this.cur_DepartmentIDn = this.curmenu_departmentlist[0].DepartmentIDn;
        this.curmenu_categorylist = this.curmenu_departmentlist[0].Categorys;
        this.cur_CategoryIDn = this.curmenu_categorylist[0].CategoryIDn;
        this.curmenu_productlist = this.curmenu_categorylist[0].Products;
        this.cur_ProductIDn = this.curmenu_productlist[0].ProductIDn;
        this.select_product(this.cur_DepartmentIDn, what);
      } else if (what == "category") {
        this.curmenu_categorylist = [];
        for (var i = 0; i < this.categorylist.length; i++) {
          this.add_product(this.categorylist[i].IDn, what, false);
        }
        this.cur_CategoryIDn = this.curmenu_categorylist[0].CategoryIDn;
        this.curmenu_productlist = this.curmenu_categorylist[0].Products;
        this.cur_ProductIDn = this.curmenu_productlist[0].ProductIDn;

        if (this.curmenu_categorylist.length > 0) {
          var mdidx = this.find_cur_department(this.curmenu_categorylist[0].DepartmentIDn);
          if (mdidx < 0) {
            alert("Can't find parent menu department IDn: " + this.curmenu_categorylist[0].DepartmentIDn);
            return;
          }
          this.curmenu_departmentlist[mdidx].Categorys = this.curmenu_categorylist;
        }
        this.select_product(this.cur_CategoryIDn, what);
      } else if (what == "product") {
        this.curmenu_productlist = [];
        for (var i = 0; i < this.productlist.length; i++) {
          this.add_product(this.productlist[i].IDn, what, false);
        }
        if (this.curmenu_productlist.length > 0) {
          this.cur_ProductIDn = this.curmenu_productlist[0].ProductIDn;
          var mcidx = this.find_cur_category(this.curmenu_categorylist, this.curmenu_productlist[0].CategoryIDn);
          if (mcidx < 0) {
            alert("Can't find parent menu category IDn: " + this.curmenu_productlist[0].CategoryIDn);
            return;
          }
          this.curmenu_categorylist[mcidx].Products = this.curmenu_productlist;
        }
        this.select_product(this.cur_ProductIDn, what);
      }
      this.change_cur_menu();
    },
    remove_all(what) {
      // department, category, product
      // Only product remove_all is allowed
      var products = "";
      if (what == "department") {
        alter("This option isn't allowed");
        return;
      } else if (what == "category") {
        alter("This option isn't allowed");
        return;
      } else if (what == "product") {
        if (this.curmenu_productlist.length <= 0) {
          return;
        }
        var midx = this.find_cur_category(this.curmenu_categorylist, this.curmenu_productlist[0].CategoryIDn);
        if (midx < 0) {
          alter("The product data has problem");
          return;
        }
        for (var i = 0; i < this.curmenu_productlist.length; i++) {
          var idx = this.find_product(this.productlist, this.curmenu_productlist[i].ProductIDn);
          if (idx >= 0) {
            this.productlist[i].show = 1;
          }
        }
        this.curmenu_categorylist[midx].Products = [];
        this.curmenu_productlist = [];
      }
      this.change_cur_menu();
    },
    add_menu() {
      if (this.cur_menu.Name1 == "") {
        alert("Please Input Name1");
        return;
      }
      if (this.cur_menu.Name2 == "") {
        alert("Please Input Name2");
        return;
      }
      if (this.cur_menu.LocationID == "") {
        alert("Please select Store");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("Menu", JSON.stringify(this.cur_menu));
      bodyFormData.append("GetAll", this.new_menu_get_all);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/menu/add_menu",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_MenuIDn = rt.data.MenuIDn;
              that.cur_DepartmentIDn = 0;
              that.cur_CategoryIDn = 0;
              that.isDraft = 0;
              that.get_menu_list();
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    copy_menu() {
      if (this.copy_Menu_IDn == 0) {
        alert("Please select Menu to Copy");
        return;
      }
      if (this.copy_Name1 == "") {
        alert("Please Input Name1");
        return;
      }
      if (this.copy_Name2 == "") {
        alert("Please Input Name2");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("Name1", this.copy_Name1);
      bodyFormData.append("Name2", this.copy_Name2);
      bodyFormData.append("MenuIDn", this.copy_Menu_IDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/menu/copy_menu",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_MenuIDn = rt.data.MenuIDn;
              that.cur_DepartmentIDn = 0;
              that.cur_CategoryIDn = 0;
              that.isDraft = 0;
              that.get_menu_list();
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    copy_price() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("PriceF", this.copy_PriceF);
      bodyFormData.append("PriceT", this.copy_PriceT);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/store/copy_price_group",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = rt.data.message;
              that.countdown = 2;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    // product_change(opt, prodIDn, what) {
    //   if (opt == "remove" && this.addOtherCategory && what == "product") {
    //     alert("Can't remove when add product to other category");
    //     return;
    //   }
    //   return;
    //   this.showloading = true;
    //   // opt: remove, add
    //   var bodyFormData = new FormData();
    //   bodyFormData.append("token", this.token);
    //   bodyFormData.append("user_id", this.user_id);
    //   bodyFormData.append("products", prodIDn);
    //   bodyFormData.append("what", what);
    //   bodyFormData.append("MenuIDn", this.cur_MenuIDn);
    //   bodyFormData.append("opt", opt);
    //   bodyFormData.append("addOtherCategory", this.addOtherCategory);
    //   if (opt == "add" && this.addOtherCategory && what == "product") {
    //     bodyFormData.append("DepartmentIDn", this.cur_AltDepartmentIDn);
    //     bodyFormData.append("CategoryIDn", this.cur_AltCategoryIDn);
    //   } else {
    //     bodyFormData.append("DepartmentIDn", this.cur_DepartmentIDn);
    //     bodyFormData.append("CategoryIDn", this.cur_CategoryIDn);
    //   }

    //   var that = this;
    //   axios({
    //     method: "post",
    //     url: "/Api/Web/menu/product_change",
    //     data: bodyFormData,
    //   })
    //     .then(function (response) {
    //       that.showloading = false;
    //       if (response.status == "200") {
    //         var rt = response.data;
    //         if (rt.status == 0) {
    //           //that.$router.push("/store");
    //           //that.$router.go();
    //         } else if (rt.status == 4) {
    //           alert(that.$t("No Permission"));
    //           that.$router.go(-1);
    //         } else if (rt.message != undefined) {
    //           alert(rt.message);
    //         }
    //       }
    //       that.get_menu_product(what);
    //     })
    //     .catch(function (error) {
    //       // console.log(error);
    //       that.$router.push("/login");
    //     });
    // },
    // modifier_product(idn, menu_PorductIDn) {
    //   this.currpage = "modifiers";
    //   this.cur_opt = "update";
    //   this.cur_modifierlist = null;
    //   this.new_modifierlist = null;
    //   this.cur_ProductIDn = idn;
    //   this.curmenu_ProductIDn = menu_PorductIDn;

    //   var bodyFormData = new FormData();
    //   bodyFormData.append("token", this.token);
    //   bodyFormData.append("user_id", this.user_id);
    //   bodyFormData.append("MenuIDn", this.cur_MenuIDn);
    //   bodyFormData.append("ProductIDn", idn);
    //   bodyFormData.append("MenuProductIDn", menu_PorductIDn);

    //   var that = this;
    //   axios({
    //     method: "post",
    //     url: "/Api/Web/menu/get_modifier",
    //     data: bodyFormData,
    //   })
    //     .then(function (response) {
    //       if (response.status == "200") {
    //         var rt = response.data;
    //         if (rt.status == 0) {
    //           that.cur_modifierlist = rt.data.cur_modifiers;
    //           that.new_modifierlist = rt.data.new_modifiers;
    //           that.cur_product = rt.data.product;
    //           that.curmenu_product = rt.data.menu_product;
    //         } else if (rt.status == 4) {
    //           alert(that.$t("No Permission"));
    //           that.$router.go(-1);
    //         } else if (rt.message != undefined) {
    //           alert(rt.message);
    //         }
    //       }
    //     })
    //     .catch(function (error) {
    //       // console.log(error);
    //       that.$router.push("/login");
    //     });
    // },

    // add_modifier(idn) {
    //   var bodyFormData = new FormData();
    //   bodyFormData.append("token", this.token);
    //   bodyFormData.append("user_id", this.user_id);
    //   bodyFormData.append("MenuIDn", this.cur_MenuIDn);
    //   bodyFormData.append("ProductIDn", this.cur_ProductIDn);
    //   bodyFormData.append("ModifierGroupIDn", idn);
    //   bodyFormData.append("opt", "add");

    //   var that = this;
    //   axios({
    //     method: "post",
    //     url: "/Api/Web/menu/modifier_change",
    //     data: bodyFormData,
    //   })
    //     .then(function (response) {
    //       if (response.status == "200") {
    //         var rt = response.data;
    //         if (rt.status == 0) {
    //           return that.modifier_product(
    //             that.cur_ProductIDn,
    //             that.curmenu_ProductIDn
    //           );
    //         } else if (rt.status == 4) {
    //           alert(that.$t("No Permission"));
    //           that.$router.go(-1);
    //         } else if (rt.message != undefined) {
    //           alert(rt.message);
    //         }
    //       }
    //     })
    //     .catch(function (error) {
    //       // console.log(error);
    //       that.$router.push("/login");
    //     });
    // },
    // delete_modifier(idn) {
    //   var bodyFormData = new FormData();
    //   bodyFormData.append("token", this.token);
    //   bodyFormData.append("user_id", this.user_id);
    //   bodyFormData.append("MenuIDn", this.cur_MenuIDn);
    //   bodyFormData.append("ProductIDn", this.cur_ProductIDn);
    //   bodyFormData.append("ModifierGroupIDn", idn);
    //   bodyFormData.append("IDn", idn);
    //   bodyFormData.append("opt", "delete");

    //   var that = this;
    //   axios({
    //     method: "post",
    //     url: "/Api/Web/menu/modifier_change",
    //     data: bodyFormData,
    //   })
    //     .then(function (response) {
    //       if (response.status == "200") {
    //         var rt = response.data;
    //         if (rt.status == 0) {
    //           return that.modifier_product(
    //             that.cur_ProductIDn,
    //             that.curmenu_ProductIDn
    //           );
    //         } else if (rt.status == 4) {
    //           alert(that.$t("No Permission"));
    //           that.$router.go(-1);
    //         } else if (rt.message != undefined) {
    //           alert(rt.message);
    //         }
    //       }
    //     })
    //     .catch(function (error) {
    //       // console.log(error);
    //       that.$router.push("/login");
    //     });
    // },

    // async update_color(idn, what, what2) {
    //   var bodyFormData = new FormData();
    //   bodyFormData.append("token", this.token);
    //   bodyFormData.append("user_id", this.user_id);
    //   bodyFormData.append("MenuIDn", this.cur_MenuIDn);
    //   bodyFormData.append("BtnColor", this.btnToColor);
    //   bodyFormData.append("what", what);
    //   bodyFormData.append("what2", what2);
    //   bodyFormData.append("IDn", idn);
    //   bodyFormData.append("opt", "delete");

    //   var that = this;
    //   axios({
    //     method: "post",
    //     url: "/Api/Web/menu/update_color",
    //     data: bodyFormData,
    //   })
    //     .then(function (response) {
    //       if (response.status == "200") {
    //         var rt = response.data;
    //         if (rt.status == 0) {
    //           ;
    //         } else if (rt.status == 4) {
    //           alert(that.$t("No Permission"));
    //           that.$router.go(-1);
    //         } else if (rt.message != undefined) {
    //           alert(rt.message);
    //         }
    //       }
    //     })
    //     .catch(function (error) {
    //       // console.log(error);
    //       that.$router.push("/login");
    //     });
    // },
    async product_submit() {
      this.product_modify = false; // Close popup window

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      for (var key in this.curmenu_product) {
        if (this.curmenu_product[key]) {
          bodyFormData.append(key, this.curmenu_product[key]);
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/menu/product_edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.currpage = "products";
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    change_online() {
      this.change_cur_menu();

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("MenuIDn", this.cur_menu.IDn);
      bodyFormData.append("IsOnline", this.cur_menu.IsOnline);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/menu/isonline",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.menulist = rt.data.menus;
              // that.cur_MenuIDn = rt.data.MenuIDn;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    // Calculate Menu idx value
    set_menu_department_idx() {
      var len = this.curmenu_departmentlist.length;
      for (var i = 0; i < len; i++) {
        this.curmenu_departmentlist[i].idx = this.cur_idx++;
      }
    },
    set_menu_category_idx() {
      var len = this.curmenu_categorylist.length;
      for (var i = 0; i < len; i++) {
        this.curmenu_categorylist[i].idx = this.cur_idx++;
      }
    },
    set_menu_product_idx() {
      var len = this.curmenu_productlist.length;
      for (var i = 0; i < len; i++) {
        this.curmenu_productlist[i].idx = this.cur_idx++;
      }
    },
    // Change to save current menu changes, reload???, redraw???
    changed_store() {
      this.change_cur_menu();
    },
    // Change to save current menu changes, reload???, redraw???
    change_cur_menu() {
      if (this.sync_to_server) {
        clearTimeout(this.sync_to_server);
      }
      this.sync_to_server = setTimeout(this.do_change_cur_menu, 200);
    },
    // Change to save current menu changes, reload???, redraw???
    do_change_cur_menu() {
      this.showloading = true;

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("MenuIDn", this.cur_MenuIDn);
      var Content = {menu: this.cur_menu, departments: this.curmenu_departmentlist};
      bodyFormData.append("Content", JSON.stringify(Content));
      this.resize_buttons();
 
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/menu/save_menu_draft",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.isDraft = true;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_menu_list() {
      this.showloading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("MenuIDn", this.cur_MenuIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Menu",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.stores;
              if (that.storelist.length <= 0) {
                that.$router.push("/store");
                return;
              }
              that.statuses = rt.data.statuses;
              that.isDraft = rt.data.isDraft;
              that.cur_MenuIDn = rt.data.MenuIDn;
              that.menulist = rt.data.menus;
              that.departmentlist = rt.data.departments;
              that.getcur_menu();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async upload_ldw() {
      var msg = "";
      this.upload_file_list = [];
      this.upload_file_ids = [];
      this.upload_product_dialog = true;
      this.upload_show_close = false;

      for (var dmi = 0; dmi < this.curmenu_departmentlist.length; dmi++) {
        for (var cmi = 0; cmi < this.curmenu_departmentlist[dmi].Categorys.length; cmi++) {
          for (var pmi = this.curmenu_departmentlist[dmi].Categorys[cmi].Products.length - 1; pmi >= 0; pmi--) {
            var mprd = this.curmenu_departmentlist[dmi].Categorys[cmi].Products[pmi];
            this.upload_file_ids.push(mprd.ProductIDn);
          }
        }
      }
      this.upload_ldw_one();
    },
    scrolldown(msg) {
      this.upload_file_list.push(msg);
      var container = this.$el.querySelector("#upload_product_ref");
      container.scrollTop = container.scrollHeight;
    },
    async upload_ldw_one() {
      var ProductIDn = this.upload_file_ids[this.upload_file_cnt++];
      if ((ProductIDn == undefined) || (this.upload_file_cnt >= this.upload_file_ids.length)) {
        this.upload_show_close = true;
        // this.upload_product_dialog = false;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ProductIDn", ProductIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_setting/uploadLdwImage",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            console.log("one200: ", response); //XXXXXXXXXXXXXXXXXXXXX
            var rt = response.data;
            if (rt.status == 0) {
              console.log("one1", rt.data.message); //XXXXXXXXXXXXXXXXXXXXX
              that.scrolldown(rt.data.message);

              that.upload_ldw_one();
            } else if (rt.status == 4) {
              console.log("one2"); //XXXXXXXXXXXXXXXXXXXXX
              that.upload_show_close = true;
              that.scrolldown("No Permission");
            } else if (rt.message != undefined) {
              console.log("one3"); //XXXXXXXXXXXXXXXXXXXXX
              that.upload_show_close = true;
              that.scrolldown(rt.message);
            }
          } else {
            console.log("one4"); //XXXXXXXXXXXXXXXXXXXXX
            that.upload_show_close = true;
            that.scrolldown("Upload Failured!!!");
          }
        })
        .catch(function (error) {
          // console.log(error);
          console.log("one5",error); //XXXXXXXXXXXXXXXXXXXXX
          that.upload_show_close = true;
          that.scrolldown("Upload Failured !!");
        });
    },
    async get_ldw() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_setting/getLdwCode",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              var ldw = rt.data.ldw;
              if (ldw && (ldw.length > 0)) {
                that.hasLdwUpload = true;
              }
              console.log("LDW ===>>> ",that.hasLdwUpload, ldw); //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      let name = getStore("username");
      var today = new Date();
      let todaystr = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
      today.setFullYear(today.getFullYear() + 10);
      let tenyearstr = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');

      this.new_menu.StartDate = todaystr;
       this.new_menu.EndDate = tenyearstr;

      if (name && name != undefined && name == "PKAdmin") {
        this.pkadmin = true;
      }

      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      this.get_ldw();
      return this.get_menu_list();
    },
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    if (window.innerWidth > 1024) {
      this.panel_left = { width: "280px" };
    }
    this.init_data();
  },
};
</script>
<style scoped>
.content {
  padding-top: 80px;
}
.left_menu_font {
  font-size: .8rem;
}
.left_menu_font .border-dark {
  border-color: #bbbbbb !important;
  font-weight: bold;
  position: relative;
}
.department_div {
  border: 1px solid #bbbbbb;
  width: 100%;
  height: 80px;
  overflow: auto;
  position: relative;
  margin-bottom: .1rem;
}
.department_div2 {
  border: 1px solid #bbbbbb;
  width: 100%;
  height: 60px;
  overflow: auto;
  position: relative;
  margin-bottom: .1rem;
}
.category_div {
  border: 1px solid #bbbbbb;
  width: 100%;
  overflow: auto;
  position: relative;
  height: 120px;
  margin-bottom: .1rem;
}
.category_div2 {
  border: 1px solid #bbbbbb;
  width: 100%;
  overflow: auto;
  position: relative;
  height: 144px;
  margin-bottom: .1rem;
}
.product_div {
  border: 1px solid #bbbbbb;
  width: 100%;
  overflow: auto;
  position: relative;
  height: 460px;
}
.product_div2 {
  border: 1px solid #bbbbbb;
  width: 100%;
  overflow: auto;
  position: relative;
  height: 306px;
}
.department_button {
  padding-top: 20px;
  width: 100%;
  height: 100px;
  overflow: auto;
  position: relative;
}
.department_button2 {
  padding-top: 8px;
  width: 100%;
  height: 66px;
  overflow: auto;
  position: relative;
}
.category_button {
  padding-top: 70px;
  width: 100%;
  height: 220px;
  overflow: auto;
  position: relative;
}
.category_button2 {
  padding-top: 44px;
  width: 100%;
  height: 147px;
  overflow: auto;
  position: relative;
}
.product_button {
  padding-top: 140px;
  width: 100%;
  height: 600px;
  overflow: auto;
  position: relative;
}
.product_button2 {
  padding-top: 96px;
  width: 100%;
  height: 400px;
  overflow: auto;
  position: relative;
}
.department_list {
  background: #e0e0e0;
}
.menulabel {
  font-size: 14px;
}
.category_list {
  background: #e0e0e0;
}
.product_list {
  background: #e0e0e0;
}
.meun_pad_list {
  padding-left: 0px;
  padding-right: 0px;
}
.search-button {
  margin-top: 2rem;
}
.card .table td {
  padding: 0.5rem;
}
.product_select {
  background: #e0e0e0;
  border: 2px solid #bbbbbb;
  border-radius: 5px;
  height: 24rem;
  margin-bottom: 1rem;
  overflow: auto;
}
.page_hr {
  margin: 0px;
}
.product {
  color: #202020;
}
.panel-middle {
  width: 20px;
}
.panel-middle2 {
  width: 8px;
}
.pad_panel {
  width: 674px;
  height: 663px;
  background: chartreuse;
  line-height: 14px;
}
.pad_panel2 {
  width: 445px;
  height: 440px;
  background: chartreuse;
  line-height: 7px;
}
.panel_list {
  height: 10px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 5px;
  border: 1px none;
  overflow: auto;
  position: relative;
}
.left_panel_sm_font {
  font-size: 0.5rem;
}
.hr-line {
  width: 100%;
  height: 3px;
  cursor: row-resize;
  background: #ffffff;
  color: #ffffff;
  border-top: 1px solid #606060;
  border-bottom: 1px solid #606060;
  border-right: none;
  border-left: none;
  position: relative;
}
.colorsquire {
  width: 24px;
  height: 24px;
  margin-left: 6px;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 2px 2px #606060;
}
.menu-button-color {
  top: 8px;
}
.el-input-number--mini {
  width: 95px;
}
.menupage .el-form-item,.el-form-item--small {
  margin-bottom: 0px;
}
.menupage .widthdate {
  width: 60%;
}
.menupage .el-select {
  width: 95px;
}
.menupage .leftmenuitem {
  cursor: pointer;
}
.upload_ldw {
  margin-left: 2rem;
}
.upload_product_div {
  height: 10rem;
  overflow: scroll;
}
</style>
